.equations-container {
	margin-top: 2em;
}

.equations-container .toggle {
	margin: 0 0 1em 1em;
	float: right;
}

.equations-container table {
	width: 100%;

	border-collapse: collapse;
}

.equations-container thead {
	border-bottom: 2px solid #333;
}

.equations {
	margin-bottom: 1em;
}

.terms .variable-name, .equations .equation {
	font-family: monospace;
}
