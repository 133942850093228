.modal {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: none;
}

.modal.active {
	display: block;
}

.modal [role=dialog] {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 80%;
	overflow-y: auto;

	padding: 1em;

	background-color: #fff;
	transform: translate(-50%, -50%);
	z-index: 2;
}

.modal [role=dialog] {
	text-align: center;
}

.modal [role=dialog] header, .modal [role=dialog] .content {
	text-align: left;
}

.modal [role=dialog] > header {
	margin-bottom: 0.8em;
}

.modal [role=dialog] > header h1 {
	margin: 0;
	padding: 0;

	display: inline;
	font-size: 1.2em;
}

.modal [role=dialog] > header button {
	float: right;
}

.modal .mask {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1;
}
