header {
	position: relative;
	display: block;
	margin-bottom: 1em;
}

header h1 {
	margin: 0;
}

header h2 {
	margin: 0;

	font-weight: normal;
	font-size: 1em;
}

header menu {
	position: absolute;
	top: 0;
	right: 0;

	text-align: right;
}

header menu button {
	margin-left: 1em;
}

header .locale-menu {
	margin-bottom: 0.3em;

	font-size: 0.7em;
}
