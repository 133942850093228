html {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

main {
	max-width: 800px;
	margin: 0 auto;
	color: #444;
}

main a {
	color: inherit;
	text-decoration: underline;
}

button {
	background-color: #eee;
	border: none;
	padding: 0.3em;

	cursor: pointer;
}

.content {
	margin-bottom: 2em;
}

.content > .nutrition {
	float: left;
}

.content > .food {
	margin-left: 19em;
}

.tutorial-instruction {
	background: linear-gradient(135deg, #777 33%, #eee, #777 66%);
	display: none;
}

.tutorial-active .tutorial-instruction {
	display: block;
}

.tutorial-active .tutorial-input {
	background: linear-gradient(135deg, #eee 33%, #fff, #eee 66%);
}

.tutorial-instruction, .tutorial-active .tutorial-input {
	background-size: 300% 300%;
	animation-name: glint;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

.tutorial-step-1 {
	animation-delay: 0;
}

.tutorial-step-2 {
	animation-delay: 1s;
}

.tutorial-step-3 {
	animation-delay: 2s;
}

@keyframes glint {
	90% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 120% 0%;
	}
}

.clearfix:after {
	content: '';
	display: table;
	clear: both;
}
