.tutorial-instruction {
	background-color: #777;
	padding: 0.5em;
	color: #eee;
	font-size: 0.8em;
	border-radius: 0.3em;
}

.tutorial-instruction h1 {
	margin: 0 0 0.2em 0;

	font-weight: bold;
	font-size: inherit;
}

.tutorial-instruction p {
	margin: 0;
}

.tutorial-instruction button {
	float: right;
	padding-top: 0;
	padding-right: 0;

	background-color: inherit;
	color: inherit;
}
