.ingredient-list {
	width: 100%;
}

.ingredient-list-item .amount {
	padding-right: 0.3em;
	width: 1em;

	font-size: 2em;
	white-space: nowrap;
}

.ingredient-list-item .nutrition-facts {
	display: block;
	border: 0;
	padding: 0;

	background-color: inherit;
	cursor: pointer;
	font-size: 0.8em;
	text-decoration: underline;
}

.ingredient-list-item .remove {
	margin-left: 2em;
	float: right;

	font-size: 0.8em;
}

.ingredient-list-container .tutorial-instruction {
	width: 20em;
}
