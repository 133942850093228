.nutrition {
	position: relative;

	font-family: sans-serif;
	border: solid 0.1em #777;
	width: 18em;
}

.nutrition fieldset {
	border: none;
	padding: 0.3em;
}

.nutrition legend {
	margin: 0;
	padding: 0;
	width: 100%;

	font-size: 1.8em;
	font-weight: bold;
}

.nutrition .tutorial-instruction {
	position: absolute;
	bottom: -1em;
	left: -1em;
	width: 60%;
}

.nutrition input:disabled {
	background-color: inherit;
	color: inherit;
	appearance: none;
	-webkit-appearance: none;
	margin: 0;
}

.nutrition .calories {
	border-bottom: 0.5em solid #000;
}

.nutrition .calories .per-serving, .nutrition thead {
	font-size: 0.6em;
	font-weight: bold;
}

.nutrition .calories label {
	font-size: 1.5em;
	font-weight: bold;
}

.nutrition .calories input {
	font-size: 1.5em;
}

.nutrition table {
	width: 100%;

	border-collapse: collapse;
	border-bottom: solid 0.5em #000;
}

.nutrition .amount tr {
	border-bottom: none;
}

.nutrition .percent-dv td {
	padding: 0.5em 0;
	text-align: right;
}

.nutrition legend, .nutrition tr {
	border-bottom: 1px solid #777;
}

.nutrition input {
	border: none;
	width: 3em;

	background-color: #eee;
	text-align: right;
}

.nutrition .primary {
	font-weight: bold;
}

.nutrition tbody tr:not(.primary) td:first-child {
	padding-left: 1em;
}

.nutrition .pct-dv {
	text-align: right;
}

.nutrition .percent-dv-explanation {
	font-size: 0.7em;
}
