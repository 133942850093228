[role=alert] {
	position: absolute;
	top: 0;

	padding: 0.5em;

	background-color: #777;
	border-radius: 0 0 0.5em 0.5em;
	color: #eee;
	transition-property: transform;
	transition-duration: 0.5s;
	z-index: 2;
}

[role=alert] > p:first-child {
	margin-top: 0;
}

[role=alert] > p:last-child {
	margin-bottom: 0;
}

[role=alert] .actions {
	text-align: right;
}

[role=alert].inactive {
	transform: translateY(-100%);
}
