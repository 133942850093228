.search {
	position: relative;
}

.search .tutorial-instruction {
	position: absolute;
	top: -1em;
	right: -1em;
	width: 10em;
}

.search input[type=text] {
	border: none;
	padding: 0.3em;
	width: 100%;

	background-color: #eee;
}

.search [role=listbox] {
	position: absolute;

	margin: 0;
	padding: 0;
	max-height: 0;
	overflow-y: auto;

	background-color: #fff;
	transition-property: max-height;
	transition-duration: 0.5s;
	z-index: 1;
}

.search [role=listbox].has-results {
	max-height: 30em;
}

.search [role=listbox] [role=option] {
	margin-left: 1em;

	list-style-type: none;
	cursor: pointer;
}

.search [role=listbox] [role=option].focused {
	background-color: #eee;
}

.search [role=listbox] [role=option][aria-selected=true]::before {
	content: '\2713';
	position: absolute;
	left: 0.1em;
}
