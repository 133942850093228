.external-project h3 {
	margin-bottom: 0;
}

.external-project a {
	margin: 0 0 1em 0;

	font-size: 0.7em;
}

.external-project summary {
	cursor: pointer;
}

pre.license {
	white-space: pre-wrap;
}
