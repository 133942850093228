.locale-menu input {
	position: absolute;
	left: -9999px;
}

.locale-menu label {
	margin-left: 0.5em;

	cursor: pointer;
}

.locale-menu :checked + label {
	font-weight: bold;
}
