footer {
	font-size: 0.8em;
	text-align: right;
}

footer p {
	margin: 0;
}

footer button {
	background-color: inherit;
	font-size: inherit;
	text-decoration: underline;
}
